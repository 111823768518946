import React from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import styles from "./InteractiveSolutions.module.css"; // Importing the CSS module for scoped styling

const InteractiveSolutions = () => {
  return (
    <div className="PaddingForApp Margin-TopAllService">
      <section
        className={`${styles.studentWellbeingHeader} py-5 text-center text-white`}
      >
        <div className={styles.container}>
          {/* Title with Zoom Animation */}
          <Zoom triggerOnce>
            <h1 className="display-4 fw-bold mb-3">Interactivity Solutions</h1>
          </Zoom>

          {/* Description with Fade Animation */}
          <Fade direction="up" triggerOnce delay={300}>
            <p className="lead fw-bold">
              Welcome to a new era of interactive education! Enhance engagement
              in your classrooms with our tailored interactive solutions
              designed for educational institutions across all levels. Our
              cutting-edge interactive displays and software tools will enable
              you to develop inspiring, contemporary classrooms and dynamic
              learning environments.
            </p>
          </Fade>
        </div>
      </section>
      <div className={`${styles.container} text-start`}>
        <section className={styles.section}>
          <h2 className={styles.heading}>
            Interactive Education and Collaboration
          </h2>
          <p className={styles.paragraph}>
            Enhance student engagement by establishing an appropriate learning
            atmosphere through our interactive displays and software solutions.
            Foster interaction among students and promote innovative
            collaborative opportunities with a diverse range of applications and
            educational games.
          </p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.heading}>Practical and User-Friendly</h2>
          <p className={styles.paragraph}>
            Our interactive displays are designed for educators of all ages,
            allowing for quick and easy adoption. They are equipped with a range
            of practical training tools. With unique innovative software
            solutions, users can operate the interactive display as they would a
            traditional blackboard, perform virtual and visual scientific
            experiments, enhance math lessons with a multitude of math and
            geometry tools, or explore human anatomy in three-dimensional views.
            The potential applications of our interactive display are limitless.
          </p>
        </section>
      </div>
    </div>
  );
};

export default InteractiveSolutions;
