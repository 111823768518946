import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import about from "../assets/about-us.jpg";
import "./about.css";

const About = () => {
  return (
    <div>
      <Container className="about-section" id="aboutUs">
        <Row>
          <Col md={6} className="text-center">
            <h2>About Us</h2>
            <p>
              At Teskha Technologies, we are dedicated to pioneering change in
              the educational landscape through cutting-edge technology and
              unwavering support. Established with the mission of driving
              innovation in education, we bring together the finest in
              educational technology solutions, products, and professionals from
              around the globe. Our aim is to support schools and institutions
              in overcoming obstacles to digital transformation and to open
              doors to new learning possibilities.
            </p>
          </Col>
          <Col md={6}>
            <img src={about} alt="about" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
