// MobileDeviceManagement.js
import React from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import styles from "./MobileDeviceManagement.module.css";

const MobileDeviceManagement = () => {
  return (
    <div className="PaddingForApp Margin-TopAllService">
      <section
        className={`${styles.studentWellbeingHeader} py-5 text-center text-white`}
      >
        <div className={styles.container}>
          {/* Title with Zoom Animation */}
          <Zoom triggerOnce>
            <h1 className="display-4 fw-bold mb-3">
              Mobile Device Management - Cross Platform/OS
            </h1>
          </Zoom>

          {/* Description with Fade Animation */}
          <Fade direction="up" triggerOnce delay={300}>
            <p className="lead fw-bold">
              The initiative aims to update the conventional directory structure
              that provides a cloud-based platform specifically engineered to
              safeguard identities, oversee devices, and guarantee secure access
              to a range of IT resources.
            </p>
          </Fade>
        </div>
      </section>
      <div className={`${styles.container} text-start`}>

        <section className={`${styles.initiativeSection} ${styles.fadeIn}`}>
          <p className={styles.sectionDescription}>
            The platform is compatible with multiple operating systems,
            including Windows, Mac, and Linux. It is versatile enough to
            accommodate various configurations, whether deployed on-premises or
            in the cloud. Its primary objective is to deliver secure and
            adaptable access and management solutions tailored for contemporary
            IT environments.
          </p>
        </section>

        <section className={`${styles.featuresSection} ${styles.slideIn}`}>
          <h2 className={styles.sectionTitle}>Key Features</h2>
          <ul className={styles.featuresList}>
            {[
              "One platform to Manage Windows, Mac, & Linux",
              "Centrally Manage any operating system",
              "Secure all remote & on-prem devices",
              "Ensure all devices adhere to your policies",
              "Get insights and support for each OS",
              "Meet compliance requirements",
              "End-to-End insights",
            ].map((feature, index) => (
              <li key={index} className={styles.featureItem}>
                {feature}
              </li>
            ))}
          </ul>
        </section>

        <section className={`${styles.identitySection} ${styles.fadeIn}`}>
          <h2 className={styles.sectionTitle}>
            Identity and Directory Management
          </h2>
          <p className={styles.sectionDescription}>
            This platform also includes advanced identity and directory
            management capabilities, allowing centralized control and management
            of user identities across all platforms and operating systems.
          </p>
        </section>
      </div>
    </div>
  );
};

export default MobileDeviceManagement;
