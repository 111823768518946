import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import HeroSection from './components/HeroSection';
import NavbarComponent from './components/NavbarComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import About from './components/About';
import LearningTeachingDevices from './components/LearningTeachingDevices';
import ChargingStorageSolution from './components/ChargingStorageSolution';
import MobileDeviceManagement from './components/MobileDeviceManagement';
import InteractiveSolutions from './components/InteractiveSolutions';
import NetworkingCablingSolutions from './components/NetworkingCablingSolutions';
import ImmersiveLearning from './components/ImmersiveLearning';
import StudentWellBeing from './components/StudentWellBeing';
import Footer from './components/Footer';
import AboutUsPage from './components/AboutUsPage';


const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/Learning-and-Teaching-Devices" element={<LearningTeachingDevices/>} />
      <Route path="/Charging-and-Storage-Solution" element={<ChargingStorageSolution />} />
      <Route path="/Mobile-Device-Management" element={<MobileDeviceManagement/>} />
      <Route path="/Interactive-Solutions" element={<InteractiveSolutions />} />
      <Route path="/Networking-Cabling-Solutions" element={<NetworkingCablingSolutions />} />
      <Route path="/Immersive-Learning" element={<ImmersiveLearning />} />
      <Route path="/Student-Well-Being" element={<StudentWellBeing/>} />
    </Routes>
  );
};

function App() {
  return (
    <Router>
    <div className="App">
      <NavbarComponent />
      <AppRoutes/>
      <Footer/>
    </div>
    </Router>
  );
}

export default App;
