import React from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import styles from "./ImmersiveLearning.module.css";

export default function ImmersiveLearning() {
  return (
    <>
    <div className="PaddingForApp Margin-TopAllService">
    <section
        className={`${styles.studentWellbeingHeader} py-5 text-center text-white`}
      >
        <div className={styles.container}>
          {/* Title with Zoom Animation */}
          <Zoom triggerOnce>
            <h1 className="display-4 fw-bold mb-3">
            Immersive Learning Spaces
            </h1>
          </Zoom>

          {/* Description with Fade Animation */}
          <Fade direction="up" triggerOnce delay={300}>
            <p className="lead fw-bold">
            Immersive Learning Spaces are transforming the educational landscape
            by providing students with an extraordinary experience in virtual
            environments, all without the need for a headset. These cutting-edge
            facilities enable learners to move beyond the limitations of
            conventional education, creating a setting that encourages the
            development of foundational knowledge, the exploration of new
            frontiers, and the investigation of subjects that extend beyond the
            traditional classroom.
            </p>
          </Fade>
        </div>
      </section>

       <section className={`py-5 ${styles.cardsSection}`}>
      <div className="container">
        <div className="row">
          {/* Card 1 */}
          <div className="col-md-3 col-sm-6 mb-4 d-flex">
            <div className={`card ${styles.card} shadow-lg border-0 rounded-lg w-100`}>
              <div className="card-body">
                <h5 className="card-title text-center text-danger">{`FULLY INTERACTIVE`}</h5>
                <p className="card-text text-muted text-center">
                  Immerse yourself completely. Interact with every surface, add sounds, smells, and sensory effects.
                </p>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-md-3 col-sm-6 mb-4 d-flex">
            <div className={`card ${styles.card} shadow-lg border-0 rounded-lg w-100`}>
              <div className="card-body">
                <h5 className="card-title text-center text-danger">{`CREATE YOUR OWN SCENARIOS`}</h5>
                <p className="card-text text-muted text-center">
                  Create the exact environment you want. From emergency roadside scenarios to the forests of Narnia, our rooms are fully customizable to your needs.
                </p>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-md-3 col-sm-6 mb-4 d-flex">
            <div className={`card ${styles.card} shadow-lg border-0 rounded-lg w-100`}>
              <div className="card-body">
                <h5 className="card-title text-center text-danger">{`LIMITLESS EXPERIENCES`}</h5>
                <p className="card-text text-muted text-center">
                  Choose from hundreds of pre-built scenarios or build your own with our template-based software. You can easily create and edit your immersion space exactly how you see fit. The only limit is your imagination.
                </p>
              </div>
            </div>
          </div>

          {/* Card 4 */}
          <div className="col-md-3 col-sm-6 mb-4 d-flex">
            <div className={`card ${styles.card} shadow-lg border-0 rounded-lg w-100`}>
              <div className="card-body">
                <h5 className="card-title text-center text-danger">{`TRAINING & SUPPORT`}</h5>
                <p className="card-text text-muted text-center">
                  From day one our team works with you to create exactly what you want. With professional training and ongoing support, your immersive space will always perform at its best.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
    </>
  );
}
