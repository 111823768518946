
// import React, { useState, useEffect } from "react";
// import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
// import { FaTimes } from "react-icons/fa";
// import "./NavbarComponent.css";
// // import Logo from "../Logo.png";
// import Logo from "../logo-symbol.png";
// import { Link, useLocation } from 'react-router-dom';

// const NavbarComponent = () => {
//   const location = useLocation();
//   const isHomePage = location.pathname === '/';
//   const menuItemStyle = {
//     color: isHomePage ? 'white' : 'black',  // Change 'blue' to any color you prefer
//   };


//   const [scrolling, setScrolling] = useState(false);
//   const [sidebarVisible, setSidebarVisible] = useState(false);

//   const handleScroll = () => {
//     if (window.scrollY > 20) {
//       setScrolling(true);
//     } else {
//       setScrolling(false);
//     }
//   };

//   const toggleSidebar = () => {
//     setSidebarVisible(!sidebarVisible);
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <>
//       <Navbar
//         fixed="top"
//         expand="lg"
//         className={`navbar navbar-expand-lg navbarSetting ${
//           scrolling ? "bg-white" : "bg-transparent"
//         } transition-all`}
//       >
//         <Container>
//           <Navbar.Brand href="#">
//             <img src={Logo} alt="Logo" className="image-setting" />
//             <span className={`LogoHandle ${scrolling ? "nav-link-black" : ""}`} style={menuItemStyle}>
//               Teskha
//             </span>
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="navbar-nav" />
//           <Navbar.Collapse id="navbar-nav">
//             <Nav className="ms-auto">
//               <Nav.Link
//                 href="/"
//                 className={scrolling ? "nav-link-black" : ""}
//                 style={menuItemStyle}
//               >
//                 Home
//               </Nav.Link>

//               {/* Dropdown for Products & Solutions */}
//               <NavDropdown
//                 title={<span className={scrolling ? "nav-link-black" : ""} style={menuItemStyle}>Products & Solutions</span>} 
//                 id="products-dropdown"
                
//               >
//                 <NavDropdown.Item href="/Learning-and-Teaching-Devices">
//                   Learning and Teaching Devices
//                 </NavDropdown.Item>
//                 <NavDropdown.Divider />
//                 <NavDropdown.Item href="/Charging-and-Storage-Solution">
//                   Charging and Storage Solutions
//                 </NavDropdown.Item>
//                 <NavDropdown.Divider />
//                 <NavDropdown.Item href="/Mobile-Device-Management">
//                   Mobile Device Management - Cross Platform/OS
//                 </NavDropdown.Item>
//                 <NavDropdown.Divider />
//                 <NavDropdown.Item href="/Interactive-Solutions">
//                   Interactivity Solutions
//                 </NavDropdown.Item>
//                 <NavDropdown.Divider />
//                 <NavDropdown.Item href="/Networking-Cabling-Solutions">
//                   High-end Networking and Cabling Solutions
//                 </NavDropdown.Item>
//                 <NavDropdown.Divider />
//                 <NavDropdown.Item href="/Immersive-Learning">
//                   Immersive Learning Spaces
//                 </NavDropdown.Item>
//                 <NavDropdown.Divider />
//                 <NavDropdown.Item href="/Student-Well-Being">
//                   Student's Well-being
//                 </NavDropdown.Item>
//               </NavDropdown>

//               <Nav.Link
//                 href="#aboutUs"
//                 className={scrolling ? "nav-link-black" : ""}
//                 style={menuItemStyle}
//               >
//                 About Us
//               </Nav.Link>

//               <Nav.Link
//                 href="#contact"
//                 className={scrolling ? "nav-link-black" : ""}
//                 onClick={toggleSidebar} // Trigger sidebar on click
//                 style={menuItemStyle}
//               >
//                 Contact
//               </Nav.Link>
//             </Nav>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>

//       {/* Sidebar Component */}
//       <div
//         className={`right-sidebar ${sidebarVisible ? "open" : ""}`}
//         onClick={toggleSidebar} // Close sidebar when clicked outside
//       >
//         <div className="sidebar-content" onClick={(e) => e.stopPropagation()}>
//           <button className="close-btn" onClick={toggleSidebar}>
//             <FaTimes />
//           </button>
//           <h2>Contact Us</h2>
//           <form>
//             <input type="text" placeholder="Your Name" required />
//             <input type="email" placeholder="Your Email" required />
//             <input type="number" placeholder="Your Phone no." required />
//             <textarea placeholder="Your Message" required></textarea>
//             <button type="submit">Submit</button>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default NavbarComponent;




import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import "./NavbarComponent.css";
import Logo from "../logo-symbol.png";

const NavbarComponent = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const menuItemStyle = {
    color: isHomePage ? "white" : "black",
  };

  const [scrolling, setScrolling] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleScroll = () => {
    setScrolling(window.scrollY > 20);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar
        fixed="top"
        expand="lg"
        className={`navbar navbar-expand-lg navbarSetting ${
          scrolling ? "bg-white" : "bg-transparent"
        } transition-all`}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={Logo} alt="Logo" className="image-setting" />
            <span
              className={`LogoHandle ${scrolling ? "nav-link-black" : ""}`}
              style={menuItemStyle}
            >
              Teskha
            </span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/" style={menuItemStyle} className={scrolling ? "nav-link-black" : ""}>
                Home
              </Nav.Link>

              <NavDropdown
                title={
                  <span style={menuItemStyle} className={scrolling ? "nav-link-black" : "productsBlack"} >
                    Products & Solutions
                  </span>
                }
                id="products-dropdown"
              >
                <NavDropdown.Item as={Link} to="/Learning-and-Teaching-Devices">
                  Learning and Teaching Devices
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/Charging-and-Storage-Solution">
                  Charging and Storage Solutions
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/Mobile-Device-Management">
                  Mobile Device Management - Cross Platform/OS
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/Interactive-Solutions">
                  Interactivity Solutions
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/Networking-Cabling-Solutions">
                  High-end Networking and Cabling Solutions
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/Immersive-Learning">
                  Immersive Learning Spaces
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/Student-Well-Being">
                  Student's Well-being
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link as={Link} to="/about-us" className={scrolling ? "nav-link-black" : ""} style={menuItemStyle}>
                About Us
              </Nav.Link>

              <Nav.Link as={Link} to="#contact" className={scrolling ? "nav-link-black" : ""} onClick={toggleSidebar} style={menuItemStyle}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Sidebar Component */}
      <div
        className={`right-sidebar ${sidebarVisible ? "open" : ""}`}
        onClick={toggleSidebar} // Close sidebar when clicked outside
      >
        <div className="sidebar-content" onClick={(e) => e.stopPropagation()}>
          <button className="close-btn" onClick={toggleSidebar}>
            <FaTimes />
          </button>
          <h2>Contact Us</h2>
          <form>
            <input type="text" placeholder="Your Name" required />
            <input type="email" placeholder="Your Email" required />
            <input type="number" placeholder="Your Phone no." required />
            <textarea placeholder="Your Message" required></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default NavbarComponent;
