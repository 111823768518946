import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ChargeStorage from "../assets/ChargeStorage.jpg";
import { Fade, Zoom } from "react-awesome-reveal";
import styles from "./ChargingStorageSolution.module.css";

const ChargingStorageSolution = () => {
  return (
    <div className="PaddingForApp Margin-TopAllService">
      <section
        className={`${styles.studentWellbeingHeader} py-5 text-center text-white`}
      >
        <div className={styles.container}>
          {/* Title with Zoom Animation */}
          <Zoom triggerOnce>
            <h1 className="display-4 fw-bold mb-3">
            Charging and storage solutions
            </h1>
          </Zoom>
        </div>
      </section>
      <Container className={styles.aboutSection}>
        <Row>
          <Col md={6} className="text-center">
            <p>
              charge & sync carts feature a user-friendly design and supports
              concurrent charging of iPads and Android tablets, Chromebooks and
              laptops up to 16 inches. Our devices are designed with child
              safety in mind and are exceptionally well-suited for any classroom
              with young students. Therefore, our charging solution is the
              perfect choice for any school using large-scale tablet deployment.
            </p>
            <p className="my-4">
              Our Educational Charging and Sync cart products addresses based on
              your need and usability keeping in mind, versatility, cable management & ventilation, quality, safety, maneuverability, 
              security, cost & warranty.
            </p>
          </Col>
          <Col md={6}>
            <img src={ChargeStorage} alt="childrens Using laptop" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChargingStorageSolution;
