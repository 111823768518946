// import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { Col, Container, Row } from "react-bootstrap";
// import { FaFacebookF, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
// import "./footer.css";

// const Footer = () => {
//   useEffect(() => {
//     const links = document.querySelectorAll(".footer-links a");
//     links.forEach(link => {
//       link.addEventListener("click", () => {
//         window.scrollTo(0, 0);
//       });
//     });

//     return () => {
//       // Clean up the event listeners when the component unmounts
//       links.forEach(link => {
//         link.removeEventListener("click", () => {
//           window.scrollTo(0, 0);
//         });
//       });
//     };
//   }, []);

//   return (
//     <footer className="footer-section">
//       <Container>
//         <Row>
//           <Col md={4} className="footer-about">
//             <h5>About Us</h5>
//             <p>
//               At Teskha Technologies, we drive innovation in education with
//               cutting-edge tech solutions, supporting schools and institutions
//               in overcoming digital transformation challenges and unlocking
//               new learning possibilities.
//             </p>
//           </Col>
//           <Col md={4} className="footer-links">
//             <h5>Quick Links</h5>
//             <ul>
//               <li><Link to="/Learning-and-Teaching-Devices">Learning and Teaching Devices</Link></li>
//               <li><Link to="/Charging-and-Storage-Solution">Charging and Storage Solutions</Link></li>
//               <li><Link to="/Mobile-Device-Management">Mobile Device Management</Link></li>
//               <li><Link to="/Interactive-Solutions">Interactivity Solutions</Link></li>
//               <li><Link to="/Networking-Cabling-Solutions">Networking and Cabling Solutions</Link></li>
//               <li><Link to="/Immersive-Learning">Immersive Learning Spaces</Link></li>
//               <li><Link to="/Student-Well-Being">Student's Well-being</Link></li>
//             </ul>
//           </Col>
//           <Col md={4} className="footer-social">
//             <h5>Follow Us</h5>
//             <div className="social-icons">
//               <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
//                 <FaFacebookF />
//               </a>
//               <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
//                 <FaTwitter />
//               </a>
//               <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
//                 <FaInstagram />
//               </a>
//               <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
//                 <FaLinkedin />
//               </a>
//             </div>
//           </Col>
//         </Row>
//         <Row className="footer-bottom">
//           <Col md={12} className="text-center">
//             <p className="mt-2">&copy; 2024 teskha. All rights reserved.</p>
//           </Col>
//         </Row>
//       </Container>
//     </footer>
//   );
// };

// export default Footer;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { FaFlag } from "react-icons/fa"; // You can use any flag icon library you prefer
import "./footer.css";

const Footer = () => {
  useEffect(() => {
    const links = document.querySelectorAll(".footer-links a");
    links.forEach((link) => {
      link.addEventListener("click", () => {
        window.scrollTo(0, 0);
      });
    });

    return () => {
      // Clean up the event listeners when the component unmounts
      links.forEach((link) => {
        link.removeEventListener("click", () => {
          window.scrollTo(0, 0);
        });
      });
    };
  }, []);

  return (
    <footer className="footer-section">
      <Container>
        <Row>
          <Col md={4} className="footer-about">
            <h5>About Us</h5>
            <p>
              At Teskha Technologies, we drive innovation in education with
              cutting-edge tech solutions, supporting schools and institutions
              in overcoming digital transformation challenges and unlocking new
              learning possibilities.
            </p>
          </Col>
          <Col md={4} className="footer-links">
            <h5>Quick Links</h5>
            <ul>
              <li>
                <Link to="/Learning-and-Teaching-Devices">
                  Learning and Teaching Devices
                </Link>
              </li>
              <li>
                <Link to="/Charging-and-Storage-Solution">
                  Charging and Storage Solutions
                </Link>
              </li>
              <li>
                <Link to="/Mobile-Device-Management">
                  Mobile Device Management
                </Link>
              </li>
              <li>
                <Link to="/Interactive-Solutions">Interactivity Solutions</Link>
              </li>
              <li>
                <Link to="/Networking-Cabling-Solutions">
                  Networking and Cabling Solutions
                </Link>
              </li>
              <li>
                <Link to="/Immersive-Learning">Immersive Learning Spaces</Link>
              </li>
              <li>
                <Link to="/Student-Well-Being">Student's Well-being</Link>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <h5>Our Offices</h5>
            <div className="office-location">
              <div className="d-flex text-start ">
               <div style={{width: "15px", height: "15px", marginRight: "30px"}}>
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <path
                    d="M5,4h6V28H5c-2.208,0-4-1.792-4-4V8c0-2.208,1.792-4,4-4Z"
                    fill="#ea3323"
                  ></path>
                  <path d="M10,20v8H27c2.209,0,4-1.791,4-4v-4H10Z"></path>
                  <path fill="#fff" d="M10 11H31V21H10z"></path>
                  <path
                    d="M27,4H10V12H31v-4c0-2.209-1.791-4-4-4Z"
                    fill="#317234"
                  ></path>
                  <path
                    d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                    opacity=".15"
                  ></path>
                  <path
                    d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                    fill="#fff"
                    opacity=".2"
                  ></path>
                </svg>
               </div>

                <p>
                  <strong>United Arab Emirates</strong>
                  <br />
                  Business Center, Sharjah Publishing City Free Zone, Sharjah,
                  United Arab Emirates
                  <br />
                  +971 585954198
                  <br />
                  Sales@teskha.com
                </p>
              </div>

              <div className="d-flex text-start ">
              <div style={{width: "15px", height: "15px", marginRight: "30px"}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <rect
                    x="1"
                    y="4"
                    width="30"
                    height="24"
                    rx="4"
                    ry="4"
                    fill="#173e1b"
                  ></rect>
                  <path
                    d="M10,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4h5V4Z"
                    fill="#fff"
                  ></path>
                  <path
                    d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                    opacity=".15"
                  ></path>
                  <path
                    d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                    fill="#fff"
                    opacity=".2"
                  ></path>
                  <path
                    d="M26.268,19.09c-2.692,2.393-6.815,2.151-9.209-.542-2.393-2.692-2.151-6.815,.542-9.209,.113-.1,.229-.196,.346-.287-2.87,.917-4.948,3.605-4.948,6.779,0,3.93,3.186,7.116,7.116,7.116,2.878,0,5.357-1.709,6.478-4.168-.104,.106-.213,.21-.326,.311Z"
                    fill="#fff"
                  ></path>
                  <path
                    fill="#fff"
                    d="M22.984 13.282L23.153 14.997 24.024 13.51 25.708 13.879 24.563 12.591 25.434 11.104 23.855 11.795 22.71 10.507 22.88 12.222 21.301 12.913 22.984 13.282z"
                  ></path>
                </svg>
                </div>

                <p>
                  <strong>Pakistan</strong>
                  <br />
                  25 Westeria Road, DHA 2<br />
                  Islamabad, Pakistan
                </p>
              </div>

              <div className="d-flex text-start ">
              <div style={{width: "15px", height: "15px", marginRight: "30px"}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <path fill="#fff" d="M8 4H24V28H8z"></path>
                  <path
                    d="M5,4h4V28H5c-2.208,0-4-1.792-4-4V8c0-2.208,1.792-4,4-4Z"
                    fill="#c53a28"
                  ></path>
                  <path
                    d="M27,4h4V28h-4c-2.208,0-4-1.792-4-4V8c0-2.208,1.792-4,4-4Z"
                    transform="rotate(180 27 16)"
                    fill="#c53a28"
                  ></path>
                  <path
                    d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                    opacity=".15"
                  ></path>
                  <path
                    d="M16.275,22.167l-.138-2.641c-.007-.16,.117-.296,.277-.304,.021,0,.042,0,.063,.004l2.629,.462-.355-.979c-.03-.08-.005-.17,.061-.223l2.88-2.332-.649-.303c-.091-.043-.135-.146-.104-.242l.569-1.751-1.659,.352c-.093,.019-.186-.029-.223-.116l-.321-.756-1.295,1.389c-.076,.08-.201,.083-.281,.007-.049-.047-.071-.115-.058-.182l.624-3.22-1.001,.578c-.095,.056-.217,.024-.272-.071-.002-.004-.004-.008-.006-.012l-1.016-1.995-1.016,1.995c-.049,.098-.169,.138-.267,.089-.004-.002-.008-.004-.012-.006l-1.001-.578,.624,3.22c.021,.108-.05,.212-.158,.233-.067,.013-.135-.009-.182-.058l-1.295-1.389-.321,.756c-.037,.087-.131,.136-.223,.116l-1.659-.352,.569,1.751c.031,.095-.013,.199-.104,.242l-.649,.303,2.88,2.332c.066,.054,.091,.144,.061,.223l-.355,.979,2.629-.462c.158-.027,.309,.079,.336,.237,.004,.021,.005,.042,.004,.063l-.138,2.641h.551Z"
                    fill="#c53a28"
                  ></path>
                  <path
                    d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                    fill="#fff"
                    opacity=".2"
                  ></path>
                </svg>
                </div>

                <p>
                  <strong>Canada</strong>
                  <br />
                  115-9626 - 148 Street, Surrey, British Columbia
                  <br />
                  V3R 0W2, Canada
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="footer-bottom">
          <Col md={12} className="text-center">
            <p className="mt-2">
              &copy; 2024 Teskha Technologies. All rights reserved.
            </p>
            <p className="mt-2">
             Powered by TechXserve
            </p>
          </Col>
        </Row>
      
      </Container>
    </footer>
  );
};

export default Footer;
